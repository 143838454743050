:root {
  /* Light theme variables */
  --bg-primary: #ffffff;
  --bg-secondary: #f8f9fa;
  --text-primary: #282c34;
  --text-secondary: #666666;
  --accent-color: #61dafb;
  --border-color: #e0e0e0;
  --shadow-color: rgba(0, 0, 0, 0.1);
}

[data-theme='dark'] {
  --bg-primary: #1a1a1a;
  --bg-secondary: #2d2d2d;
  --text-primary: #ffffff;
  --text-secondary: #b3b3b3;
  --accent-color: #61dafb;
  --border-color: #404040;
  --shadow-color: rgba(0, 0, 0, 0.3);
}

.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--bg-primary);
  color: var(--text-primary);
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--text-primary);
  padding: 20px;
}

.unselectable {
  max-width: 200px;
  width: 100%;
  height: auto;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
}

.video-container {
  position: relative;
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
  background: transparent;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  -webkit-perspective: 1000;
}

video.unselectable {
  background: transparent;
  object-fit: contain;
}

/* iOS Safari specific fixes */
@supports (-webkit-touch-callout: none) {
  .video-container {
    background: transparent !important;
  }

  video.unselectable {
    background: transparent !important;
    -webkit-backface-visibility: hidden !important;
    -webkit-transform: translateZ(0) !important;
    -webkit-perspective: 1000 !important;
    -webkit-background-clip: padding-box !important;
    background-clip: padding-box !important;
    mix-blend-mode: normal !important;
    opacity: 1 !important;
    -webkit-opacity: 1 !important;
  }
}

@media (max-width: 768px) {
  .video-container {
    background: transparent !important;
  }

  video.unselectable {
    background: transparent !important;
    -webkit-backface-visibility: hidden !important;
    -webkit-transform: translateZ(0) !important;
    -webkit-perspective: 1000 !important;
    -webkit-background-clip: padding-box !important;
    background-clip: padding-box !important;
    mix-blend-mode: normal !important;
    opacity: 1 !important;
    -webkit-opacity: 1 !important;
  }
}

.sped-up {
  filter: brightness(1.2);
}

/* Update existing styles to use theme variables */
.AppNavbar {
  background-color: var(--bg-secondary);
  border-bottom: 1px solid var(--border-color);
}

.AppNavbar a {
  color: var(--text-primary);
}

.AppNavbar a:hover {
  color: var(--accent-color);
}

footer {
  background-color: var(--bg-secondary);
  border-top: 1px solid var(--border-color);
  color: var(--text-secondary);
}

footer a {
  color: var(--text-primary);
}

footer a:hover {
  color: var(--accent-color);
}

@keyframes speedUp {
  0% {
    filter: brightness(1.2);
  }
  100% {
    filter: brightness(1);
  }
}
