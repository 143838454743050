.jitter-text {
  display: inline-block;
  white-space: pre;
}

.jitter-letter {
  display: inline-block;
  will-change: transform;
}

@keyframes shake {
  0%, 100% {
    transform: translate(0, 0) rotate(0deg);
  }
  25% {
    transform: translate(-2px, 2px) rotate(-2deg);
  }
  50% {
    transform: translate(2px, -2px) rotate(2deg);
  }
  75% {
    transform: translate(-2px, -2px) rotate(-1deg);
  }
}

.jitter-letter.active {
  animation: shake 0.5s ease-in-out infinite;
}

.jitter-letter:hover {
  cursor: pointer;
} 
